import './App.css';
import './assets/Fonts/fonts.css'
import Footer from './components/Footer/footer';
import Navbar from './components/Navbar/navbar'
import Main from './components/Main/main';

function App() {
  return (
    <div className="website" style={{ height: '100dvh' }}>
      <Navbar/>
      <Main/>
      <Footer/>
    </div>
  );
}

export default App;
