import React, { Component } from 'react';
import NSA_FULL_WHITE_LOGO from '../../assets/Images/NSA-Full-White.png';
import './navbar.css';

class Navbar extends Component {
    render() {
        return (
            <nav className='navbar navbar-expand fixed-top border-0' id='mainNav'>
                <div className='container'>
                    <a className='navbar-brand' href='./index.html'>
                        <img className='navbar-brand-logo' src={NSA_FULL_WHITE_LOGO} alt='logo' />
                    </a>
                    <button data-bs-toggle="collapse" class="navbar-toggler collapsed" data-bs-target="#navbarResponsive" type="button" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" value="Menu"><i class="fa fa-bars"></i></button>
                    <div className='collapse navbar-collapse' id='navbarResponsive'>
                        <ul className='navbar-nav ms-auto'>
                            <li className='nav-item'>
                                <a className='nav-link' href='mailto:info@nsabuildtech.com'>Get In Touch</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;