import React, { Component } from 'react';
import background from '../../assets/Images/Background.png';
import './main.css';

class Main extends Component {
    render() {
        return (
            <div className='main-body' style={{
                backgroundImage: `linear-gradient(174deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.75) 69%), url(${background})`,
                backgroundPosition: 'center bottom',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'black'
            }}>
                <div className='main-content'>
                    <div className='container'>
                        <div className='row intro'>
                            <div className='col-lg-8 mx-auto'>
                                <h1 className='brand-heading'>Design Solution For Your Space</h1>
                                <p className='intro-text'>
                                Our website is undergoing a makeover to serve you better. We're working hard to bring you a fresh new experience. We'll be back soon!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Main;